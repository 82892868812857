body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}
html, body {
  overflow-x: hidden;
  height: 100%;
}
a {
  text-decoration: none;
}
.app {
  font-family: 'DM Sans', sans-serif;
}
.container {
  max-width: 85%;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

/*--------- 
Preloader 
----------*/

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1500;
  background-color: #282547;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader.fade-out {
  opacity: 0;
  transition: all 0.6s ease;
}

.preloader .box {
  width: 85px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.preloader .box div {
  height: 16px;
  width: 16px;
  background-color: #ffdada;
  border-radius: 50%;
  animation: loaderAni 1s ease infinite;
}

.preloader .box div:nth-child(2) {
  animation-delay: 0.1s;
}

.preloader .box div:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loaderAni {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}


.disable a {
	text-decoration: none !important;
}