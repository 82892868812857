.about {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	top: 5rem;
	flex-wrap: wrap;
}
.about-overlay {
	position: absolute;
	width: 150px;
	height: 150px;
	background: #ffdada;
	border-radius: 50%;
	z-index: -1;
	top: 27px;
	left: 100px;
}

/*-------------------
  About Intro Styles 
--------------------*/
.about_intro {
	height: 780px;
	width: 80%;
	position: relative;
	display: flex;
	flex-direction: column;
	margin: auto;
}
.about_intro h1 {
	font-family: "Playfair Display", serif;
	font-weight: 700;
	font-size: 72px;
	margin: 35px 0;
	line-height: 140px;
	color: #3C386A;
}
.about_intro .row,
.about_funFacts .row {
	flex-wrap: nowrap;
}
.intro-details1 div {
	margin-bottom: 35px;
}
.intro-details1 div p,
.intro-details1 p {
	margin: 5px 0;
	font-size: 17px;
	color: #4A4A4A;
}
.intro-details2 {
	width: 320px;
	height: 200px;
	background: #282547;
	color: #ffdada;
	padding: 20px 20px 20px 45px;
}
.intro-details2-header {
	font-size: 30px;
	font-family: "Playfair Display", serif;
	font-weight: 700;
	margin: 5px 0;
	margin-top: 15px;
}
.intro-details2-white div,
.intro-details2-pink div,
.intro-details2-red div {
	width: 14px;
	height: 14px;
	border-radius: 50px;
	margin-left: -5px;
}
.intro-details2-white div {
	background: #fffafa;
}
.intro-details2-pink div {
	background: #fda5b4;
}
.intro-details2-red div {
	background: #cf455c;
}
.intro-details2-white p,
.intro-details2-pink p,
.intro-details2-red p {
	margin: 8px;
}

/*-----------------------
  About Fun Facts Styles 
------------------------*/
.about_funFacts {
	background: #3C386A;
	width: 100%;
	position: relative;
	height: 700px;
	padding: 150px 0 30px;
	color: #fff;
	/* overflow-x: hidden; */
}
.about_funFacts-cont {
	width: 85%;
	margin: auto;
	/* overflow-x: hidden; */
}
.about_funFacts-cont p {
	font-size: 19px;
	font-weight: 700;
	margin-top: 5px;
}

.funMob {
	display: none;
}

.funFacts-details div {
	margin-bottom: 35px;
}
.funFacts-details div p {
	margin: 5px 0;
	font-size: 18px;
	font-weight: 400;
}
.funFacts-img div {
	width: 300px;
	height: 300px;
	background: #2d2d2d;
	position: absolute;
	top: 175px;
	margin-left: -15px;
}
.funFacts-img img {
	position: relative;
	width: 300px;
	height: 300px;
	z-index: 1;
	margin-top: 12px;
}

/*-------------------------
  About Experience Styles 
--------------------------*/
.about_experience {
	width: 79%;
	position: relative;
	height: 740px;
	padding: 90px 0 250px;
	overflow: hidden;
	/* justify-content: space-between; */
	margin: auto;
	gap: 18rem;
}
.experience-header,
.skill-header {
	font-weight: 700;
	font-size: 20px;
	letter-spacing: 1.2px;
	color: rgba(74, 74, 74, 1);
	margin: 10px 0;
}
.experiences .hr {
	width: 224px;
}
.hr {
	margin: 0;
	background-color: #dadada;
	height: 2px;
}
.firsttt {
	margin-top: 30px !important;
}
.experience-details {
	margin-top: 40px;
}
.experience-details p,
.skills-details p,
.tool-details p {
	margin: 10px 0;
	color: #4A4A4A;
	font-size: 14px;
}

.skills-details p,
.tool-details p {
	margin: 12px 0;
}

.experience-buttonCont {
	margin-top: 105px;
}
.experience_button {
	background-color:  #3C386A;
	color: #fff;
	padding: 13px 20px;
	border-radius: 8px;
}

.experience_button:hover {
	opacity: 0.9 !important;
}

/*-----------------------
  About Skills Styles 
------------------------*/
.experience-skills .hr {
	width: 130px;
}
.skills-details,
.tool-details {
	margin-top: 20px;
}
.tools {
	margin-top: 95px;
}


@media only screen and (max-width: 1024px) { 
	.about-overlay {
		left: 60px;
	}
	.about_funFacts {
		height: 850px;
	}
	.intro-details1 div p, .intro-details1 p {
		font-size: 13px;
	}
	.about_intro .row,
	.about_funFacts .row,
	.about_experience .row {
		flex-wrap: nowrap;
	}
	.intro-details2 {
		width: 270px;
		margin-left: 21px;
	}
	.intro-details2-header {
		font-size: 23px;
		margin-top: 10px;
	}
.about_funFacts-cont {
	flex-direction: column;
}
.funFacts-img {
	position: absolute;
	right: 52px;
}
.funFacts-img img {
    width: 250px;
    height: 250px;
    z-index: 1;
		margin-top: 27px;
}
.funFacts-img div {
    width: 250px;
    height: 250px;
    top: 39px;
    margin-left: -15px;
}
.about_experience {
	gap: 9rem;
}
}

@media only screen and (max-width: 912px) {
.funFacts-details {
	width: 514px
}
.about_funFacts {
	padding: 100px 0 30px;
	height: auto;
}
.about_experience {
	gap: 3rem;
}
}

@media only screen and (max-width: 820px) {
	.funFacts-details {
		width: 424px;
	}

	.about_experience {
    gap: 4rem;
}

	.experiences {
		width: 340px;
	}
	}


@media only screen and (max-width: 768px) {
	.about {
    top: 1rem;
}

.about-overlay {
	width: 130px;
	height: 130px;
	top: 38px;
	left: 29px;
}

	.about_intro {
    width: 86%;
}

	.intro-details2 {
		height: 257px;
	}
	.funFacts-img {
		right: 50px;
	}
	.funFacts-details div p {
		font-size: 14px;
	}
	.funFacts-img img {
		width: 200px;
		height: 200px;
		z-index: 1;
	}
	.funFacts-img div {
		width: 200px;
		height: 200px;
		top: 14px;
		margin-left: -15px;
	}

	.experience-details p, .skills-details p, .tool-details p {
		font-size: 12px;

	}

	.experiences {
		margin-right: 17px;
	}

	.experience-details p {
    line-height: 20px;
	}
	
}

@media only screen and (max-width: 574px) {
	.about-overlay {
		width: 80px;
		height: 80px;
		top: -15px;
    left: 18px;
	}
	.about_intro {
		height: 860px;
	}
	.about_intro-details {
		flex-direction: column-reverse;
	}
	.about_intro h1 {
		font-size: 45px;
		margin: -21px 0 35px;
		line-height: 92px;
	}
	.intro-details2 {
		height: auto;
		padding-bottom: 2rem;
		margin: auto;
		margin-bottom: 60px;
    margin-top: 10px;
}
	.intro-details2-header {
		font-size: 17px;
	}
	.intro-details2-white p, .intro-details2-pink p, .intro-details2-red p {
		font-size: 13px;
	}
	.about_funFacts {
		height: auto;
		padding: 70px 0 30px;
	}
	.about_funFacts-cont {
    flex-direction: column-reverse;
}

.funDes {
	display: none;
}

.funMob {
	display: block;
}

	.funFacts-img {
    position: relative;
		right: 0;
		margin: auto;
}

.funFacts-img div {
	width: 270px;
	height: 270px;
}

.funFacts-img img {
	width: 270px;
	height: 270px;
}

	.about_experience {
		height: auto;
	}
	.about_experience {
		flex-direction: column;
		justify-content: space-evenly;
		padding: 90px 0 156px;
	}
	.experiences {
		margin: 0;
		width: auto;
	}
	.experience-skills {
		width: 85%;
		display: flex;
		justify-content: space-between;
	}
	.tools {
		margin-top: 0px;
		margin-left: 62px;
	}
	.funFacts-details {
		width: auto;
		margin-top: 45px;
	}

	.experience-buttonCont {
    margin-top: 15px;
}
}

@media only screen and (max-width: 414px) {
	.about {
    top: 2rem;
}

	.about-overlay {
    width: 60px;
    height: 60px;
    top: -20px;
    left: 20px;
}

	.about_experience {
		height: auto;
		gap: 3rem;
	}
	.about_intro-details {
		flex-wrap: nowrap;
		flex-direction: column-reverse;
	}
	.intro-details2 {
		width: auto;
    height: auto;
    margin: 0px;
    margin-left: 0px;
		margin-bottom: 40px;
	}
	.intro-details2-header {
		font-size: 24px;
	}
	.about_intro {
		height: auto;
    padding-bottom: 72px;
	}

	.about_intro h1 {
    font-size: 32px;
    line-height: 64px;
		margin: -21px 0 39px;
}
	.funFacts-img {
		/* right: 40px; */
		width: 85%;
		top: 0px;
	}

	.funFacts-img div {
    width: 298px;
    height: 298px;
		top: 40px;
}

	.funFacts-img img {
		width: 298px;
    height: 298px;
}

 .funFacts-details {
	position: relative;
    margin: 60px 0px 0px 0px;
	width: auto;
 }
 .about_funFacts {
    height: auto;
		padding: 45px 0 30px;
}
.tools {
    margin-top: 40px;
    margin-left: 0px;
}

.experience-skills {
flex-direction: column;
}
.skill {
	/* margin-left: -22px; */
}
.experiences {
    /* margin-left: -19px; */
}
}
@media only screen and (max-width: 375px){

	.funFacts-img div {
    width: 282px;
    height: 282px;
		top: 40px;
}

	.funFacts-img img {
		width: 282px;
    height: 282px;
}

}

@media only screen and (max-width: 360px){

	.funFacts-img div {
    width: 275px;
    height: 282px;
		top: 40px;
}

	.funFacts-img img {
		width: 275px;
    height: 282px;
}

}