/*-------------------
  Footer Styles 
--------------------*/
footer {
	width: 100%;
	height: 360px;
	position: relative;
	bottom: 0;
	background: #282547;
	color: #fff;
	justify-content: space-around;
	align-items: center;
}

footer .header {
	font-size: 20px;
	font-weight: 700;
}

footer span {
	margin-top: 15px;
	margin-left: -10rem;
}
footer span a {
	text-decoration: underline;
	color: #fff;
	font-size: 60px;
	font-weight: 400;
}
footer span p {
	margin: 5px 0px;
	font-size: 20px;
	font-weight: 400;
}
.footer_social a {
	background: #fff;
	width: 45px;
	height: 45px;
	text-align: center;
	border-radius: 50px;
	margin-right: 15px;
}
.linkedIn img {
	margin-top: 11px;
}
.twitter img {
	margin-top: 15px;
}
.dribble img {
	margin-top: 14px;
}

@media only screen and (max-width: 1024px) {
	footer span a {
	font-size: 23px;
}
footer span p {
	font-size: 13px;
}
footer {
    height: 200px;
}
}

@media only screen and (max-width: 820px) {
	footer span {
    margin-left: -6rem;
}
}

@media only screen and (max-width: 574px) {
	footer {
		height: auto;
		padding: 30px 0 35px;
		flex-direction: column;
		flex-wrap: nowrap !important;
	}

footer .header {
	margin-bottom: 6px;
}
	
footer span {
	margin-left: 0px;
	display: flex;
	flex-direction: column;
}

footer span a {
	text-align: center !important;
	font-size: 30px;
  line-height: 42px;
	margin-bottom: 10px;
}

footer span p{
	text-align: center !important; 
	font-size: 17px;
  line-height: 23px;
}

.footer_social {
	width: 185px;
  margin-top: 3em;
}

.footer_social a{
	width: 40px;
	height: 40px;
}

.linkedIn img {
	margin-top: 9px;
}
.twitter img {
	margin-top: 13px;
}
.dribble img {
	margin-top: 12px;
}
}


@media only screen and (max-width: 411px) {
	footer span {
		margin-top: 0px;
	}
}
@media only screen and (max-width: 375px) {
	footer span a {
		font-size: 26px;
	}
}
@media only screen and (max-width: 360px) {
	footer span p {
			font-size: 16px;
	}
}
@media only screen and (max-width: 320px) {
	footer span a {
		font-size: 21px;
	}
}

