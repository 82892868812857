.navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  width: 100%;
  padding: 45px 0px 20px;
  background: transparent;
  z-index: 99;
  overflow: hidden;
}

.navbar .hamburger,
.navbar_details .closeMenu {
  display: none;
}
.navbar_details li {
  list-style: none;
  margin-left: 10px;
}

.navbar_details a {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.navbar_a {
  padding: 11px;
  color: #282547;
  position: relative;
}

.navbar_a::before {
  background-color: #282547;
  content: "";
  border-radius: 0 0 4px 4px;
  top: 33px;
  height: 2px;
  opacity: 0;
  left: 0px;
  position: absolute;
  right: 0px;
  transform-origin: left center;
  transform: scaleX(0);
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  visibility: hidden;
  width: auto;
}

.navbar_a:hover::before {
  transform: scaleX(1);
  visibility: visible;
  opacity: 0.5 !important;
}

.navbar_button {
  background-color: #282547;
  color: #fff;
  padding: 13px 20px;
  border-radius: 8px;
  margin-left: 10px;
}

.navbar_button:hover {
  opacity: 0.9 !important;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #3C386A;
}

.closeMenu .bar:nth-child(1) {
  transform: translateY(6.8px) rotate(48deg);
  width: 19px;
}

.closeMenu .bar:nth-child(2) {
  transform: translateY(3.8px) rotate(-53deg);
  width: 19px;
}

@media only screen and (max-width: 1021px) {
  .navbar {
    padding-top: 34px;
  }
}

@media only screen and (max-width: 912px) {
  .navbar_details a {
    font-size: 14px;
  }
}


@media only screen and (max-width: 820px) {
  .container {
    max-width: 90%;
}
  .navbar {
    padding-top: 34px;
  }

  .navbar_details a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    max-width: 85%;
}
  .navbar_details {
    position: fixed;
    right: -79%;
    top: 0;
    flex-direction: column;
    background-color: #fff;
    width: 79%;
    text-align: center;

    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 0;
    padding: 56px 0 152px;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .navbar_logo {
    margin-left: -17px;
  }
  
  .closeMenu {
    /* margin-left: 20px; */
    padding: 18px 17px 18px 17px;
    /* background: rgba(255, 218, 218, 0.3); */
    width: 20px;
    height: 20px;
    /* border-radius: 50px; */
    position: absolute;
    right: 10px;
    margin-top: -52px;
  }

  .closeMenu div {
    margin-left: -3px;
  }

  .closeMenu .bar {
    margin: 0 !important;
  }

  .navbar_details.active {
    right: 0;
  }

  .navbar_details li {
    margin: 10px 20px;
    text-align: left;
  }

  .navbar_details li:first-of-type {
    margin-top: 42px;
  }

  .navbar_details li:last-child {
    margin-top: 34px;
  }
  .navbar .hamburger,
  .navbar_details .closeMenu {
    display: block;
    cursor: pointer;
  }
}

@media only screen and (max-width: 414px) {
  .navbar_logo {
    margin-left: -6px;
  }
}
