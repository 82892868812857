.yellowScreen {
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	padding: 50px 0px 0px;
}

.container {
	max-width: 90%;
	margin: auto;
}

/*---------------------
  Yellow Screen Styles 
-----------------------*/
.yellowScreen-back {
	position: relative;
}
.yellowScreen-back img {
	width: 30px;
}
.yellowScreen-back p {
	color: rgba(117, 117, 117, 1);
	font-size: 19px;
	font-weight: 400;
	margin: 0;
	margin-top: 3.4px;
	margin-left: -5px;
}
.yellowScreen-details {
	height: 750px;
	padding: 20px;
	position: relative;
}
.yellowScreen-details.row {
	flex-wrap: nowrap;
}
.yellowScreen-details .details-overlay {
	position: absolute;
	width: 110px;
	height: 110px;
	background: #ffdada;
	border-radius: 50%;
	z-index: -1;
	top: 64px;
	left: 17px;
}
.yellowScreen-details .details-intro h3 {
	font-family: "Playfair Display", serif;
	font-weight: 700;
	font-size: 46px;
	margin: 35px 0;
	margin-left: 32px;
	line-height: 140px;
	color:  #3C386A;
}
.yellowScreen-details .details-intro p {
	color: #4A4A4A;
	font-size: 15px;
	margin: 20px 0;
	font-weight: 400;
}
.yellowScreen-details .details-intro p a {
	text-decoration: underline;
	color: #4A4A4A;
}
.yellowScreen-details .details-intro p b {
	font-weight: 700;
}
.yellowScreen-details .details-intro div {
	margin: 45px 0;
}
.yellowScreen-details .details-intro div p {
	margin: 10px 0;
}
.yellowScreen-details .details-img {
	position: relative;
	top: 62px;
	left: 25px;
}
.yellowScreen-details .details-img-overlay {
	background: rgba(241, 243, 247, 1);
	position: relative;
	height: 430px;
	width: 430px;
	border-radius: 30px;
	transform: rotate(30deg);
}
.yellowScreen-details .details-img img {
	position: absolute;
	margin-top: -465px;
	margin-left: -112px;
}
.yellowScreen-bg {
	padding: 30px;
}
.yellowScreen .bg-1 {
	margin-top: 20px;
	background: #3C386A;
	color: #fff;
	height: 440px;
}
.yellowScreen .bg-2 {
	color: #4A4A4A;
	height: 300px;
}
.yellowScreen .bg-3 {
	color: #4A4A4A;
	height: 550px;
	background: rgba(255, 218, 218, 0.3);
}
.yellowScreen .bg-4 {
	height: 2700px;
}
.yellowScreen .bg-5,
.yellowScreen .bg-6 {
	color: #4A4A4A;
	height: 400px;
}
.yellowScreen .bg-6 .bg-cont div {
	margin-left: 190px;
}
.yellowScreen .bg-6 .bg-cont div p a {
	text-decoration: underline;
	color: #3C386A;
}
.yellowScreen .bg-cont {
	margin-top: 50px;
}
.yellowScreen .bg-cont-header {
	font-weight: 700 !important;
	font-size: 17px;
}
.yellowScreen .bg-cont p {
	font-weight: 400;
}
.yellowScreen .bg-cont div {
	margin-left: 210px;
}
.yellowScreen .bg-4 .bg-cont div {
	margin-left: 255px;
}
.yellowScreen .bg-cont-between {
	margin-top: 60px;
}
.yellowScreen .bg-cont-between-img {
	margin-top: 105px;
}

.yellowScreen .screen-img p {
	position: absolute;
	text-align: center;
	left: 0;
	right: 0;
	margin-top: 55px;
	font-weight: 600;
	font-size: 23px;
	color: #4A4A4A;
}

.yellowScreen .screen-img img {
	width: 100%;
}

.funMob {
	display: none;
}


@media only screen and (max-width: 1024px) { 
	.yellowScreen .bg-1,
	.yellowScreen .bg-2,
	.yellowScreen .bg-3,
	.yellowScreen .bg-4,
	.yellowScreen .bg-5,
	.yellowScreen .bg-6{
		height: auto;
	}

	.yellowScreen-details .details-img {
		top: -565px;
    left: 550px;
		z-index: -1;
	}
	.yellowScreen-details.row {
		flex-wrap: wrap;
	}
	.yellowScreen-details .details-img img {
		width: 430px;
		margin-top: -345px;
        margin-left: -46px;
	}
	.yellowScreen-details .details-img-overlay {
		height: 330px;
		width: 330px;
	}
	.yellowScreen .bg-cont div {
		margin-top: -60px;
	}
	.screen-img img {
		width: 800px;
	}
}

@media (max-width: 768px) {
	.yellowScreen-details .details-img-overlay {
		height: 240px;
		width: 240px;
	}
	.yellowScreen-details .details-img img {
		width: 308px;
		margin-top: -254px;
		margin-left: -30px;
	}
	.yellowScreen-details .details-img {
		top: -494px;
		left: 440px;
		z-index: -1;
	}
	.yellowScreen-details .details-intro p {
		font-size: 13px;
	}
	.yellowScreen .bg-cont {
		margin-top: 45px;
	}
	.yellowScreen .bg-1 {
		height: 572px;
	}
	.yellowScreen .bg-2 {
		height: 342px;
	}
	.yellowScreen .bg-3 {
		height: 672px;
	}
	.yellowScreen .bg-4 {
		height: 1410px;
	}
	.yellowScreen .bg-cont div {
		margin-left: 190px;
	}
	.screen-img img {
		width: 600px;
	}
	.yellowScreen .screen-img p {
		font-size: 18px;
	}
}

@media only screen and (max-width: 574px) {
	.yellowScreen-details .details-intro h3 {
		font-size: 35px;
	}
	.yellowScreen-bg .row {
		flex-direction: column;
	}
	.yellowScreen-details.row {
    flex-wrap: nowrap;
}
.yellowScreen-details {
	height: auto;
}
	.yellowScreen .bg-cont div {
		margin-left: 0px;
		margin-top: 0px;
	}
	.yellowScreen .bg-1 {
		height: 620px;
	}
	.yellowScreen .bg-2 {
		height: 425px;
	}
	.yellowScreen .bg-3 {
		height: 740px;
	}
	.screen-img img {
		width: 417px;
	}
	.yellowScreen .screen-img p {
		margin-top: 20px;
		font-size: 16px;
	}
	.yellowScreen .bg-4 {
		height: 1050px;
	}
	.yellowScreen .bg-5, .yellowScreen .bg-6 {
		height: 520px;
	}
  .yellowScreen .bg-6 .bg-cont div {
    margin-left: 0;
	}
	.yellowScreen .bg-cont-between-img {
		margin-top: 55px;
	}
	.funDes {
		display: none;
	}

	.funMob {
		display: block;
	}

	.yellowScreen-details .details-img {
		top: 	18px;
    left: 0px;
    right: 0px;
		width: 68%;
		z-index: 1;
		margin: 0 auto !important;
	}
	
	.yellowScreen-details .details-img-overlay {
    height: 200px;
    width: 200px;
}
.yellowScreen-details .details-img img {
	width: 240px;
	margin-top: -236px;
	margin-left: -20px;
}
}

@media only screen and (max-width: 414px) {
	.yellowScreen {
    padding: 6px 0px 0px;
}
	.yellowScreen-details .details-overlay {	
			width: 60px;
			height: 60px;
			top: 34px;
			left: 3px;
	}
	.yellowScreen-details .details-intro h3 {
		font-size: 28px;
		line-height: 64px;
		margin: 12px 0 35px;
		margin-left: -6px;
	}

	.yellowScreen-details .details-intro div p {
    margin: 1px 0;
}

	.yellowScreen-back img {
    width: 27px;
}

	.yellowScreen-back p {
    font-size: 16px;
}
	.yellowScreen .bg-cont-between {
    margin-top: 35px;
}
	.yellowScreen-bg .row {
		flex-direction: column;
	}
	.yellowScreen .bg-cont {
		margin-top: 11px;
	}
	.yellowScreen .bg-cont div {
		margin-left: -2px;
		margin-top: 0px;
	}
	.yellowScreen-details,
	.yellowScreen .bg-1,
	.yellowScreen .bg-2,
	.yellowScreen .bg-3,
	.yellowScreen .bg-4,
	.yellowScreen .bg-5,
	.yellowScreen .bg-6{
		height: auto;
	}
	.screen-img img {
		width: 304px;
		margin-left: 0px;
	}
	.yellowScreen .screen-img p {
		font-size: 13px;
		margin-top: 10px;
	}
	.yellowScreen .bg-6 {
		padding-bottom: 70px;
	}
	.yellowScreen .bg-6 .bg-cont div {
		margin-left: 0px;
	}
	.yellowScreen .bg-cont div p {
		font-size: 15px;
	}
}

@media only screen and (max-width: 320px){
	.yellowScreen {
		padding: 1px 0px 0px;
	}
	.yellowScreen-details .details-intro h3 {
		font-size: 17px;
		margin: 10px 0;
	}
	.yellowScreen-details .details-intro div {
		margin: 19px 0;
	}
	.yellowScreen-details .details-intro p {
		font-size: 11px;
	}
	.yellowScreen .bg-cont div p {
		font-size: 11px;
	}
	
	.screen-img img {
		width: 200px;
	}
	.yellowScreen-details .details-img-overlay {
		height: 178px;
		width: 178px;
	}
	
	.yellowScreen-details .details-img img {
		width: 200px;
		margin-top: -186px;
		margin-left: -20px;
	}
	}