.yellowPaga {
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	padding: 50px 0px 0px;
}

.container {
	max-width: 90%;
	margin: auto;
}

/*---------------------------
  Yellow Paga Screen Styles 
----------------------------*/
.yellowPaga-back {
	position: relative;
}
.yellowPaga-back img {
	width: 30px;
}
.yellowPaga-back p {
	color: rgba(117, 117, 117, 1);
	font-size: 19px;
	font-weight: 400;
	margin: 0;
	margin-top: 3.4px;
	margin-left: -5px;
}
.yellowPaga-details {
	height: 750px;
	padding: 20px;
	position: relative;
}
.yellowPaga-details.row {
	flex-wrap: nowrap;
}
.yellowPaga-details .details-overlay {
	position: absolute;
	width: 110px;
	height: 110px;
	background: #ffdada;
	border-radius: 50%;
	z-index: -1;
	top: 64px;
	left: 17px;
}
.yellowPaga-details .details-intro h3 {
	font-family: "Playfair Display", serif;
	font-weight: 700;
	font-size: 46px;
	margin: 35px 0;
	margin-left: 32px;
	line-height: 140px;
	color:  #3C386A;
}
.yellowPaga-details .details-intro p {
	color: #4A4A4A;
	font-size: 15px;
	margin: 20px 0;
	font-weight: 400;
}
.yellowPaga-details .details-intro p a {
	text-decoration: underline;
	color: #4A4A4A;
}
.yellowPaga-details .details-intro p b {
	font-weight: 700;
}
.yellowPaga-details .details-intro div {
	margin: 45px 0;
}
.yellowPaga-details .details-intro div p {
	margin: 10px 0;
}

.produ {
  width: 652px;
}

.yellowPaga-details .details-img {
	position: relative;
	top: 90px;
	left: 25px;
}
.yellowPaga-details .details-img-overlay {
	background: rgba(241, 243, 247, 1);
	position: relative;
	height: 430px;
	width: 430px;
	border-radius: 30px;
	transform: rotate(45deg);
}
.yellowPaga-details .details-img img {
	position: absolute;
	margin-top: -465px;
	margin-left: -112px;
}
.yellowPaga-bg {
	padding: 30px;
}
.yellowPaga .bg-1 {
	margin-top: 20px;
	background: #3C386A;
	color: #fff;
	height: 375px;
}
.yellowPaga .bg-2 {
	color: #4A4A4A;
	height: 380px;
}
.yellowPaga .bg-3 {
	color: #4A4A4A;
	height: 470px;
	background: rgba(255, 218, 218, 0.3);
}
.yellowPaga .bg-4 {
	height: 1820px;
}

.yellowPaga .bg-5 {
  color: hsl(0, 0%, 29%);
	height: auto;
  padding-bottom: 6rem;
	background: rgba(255, 218, 218, 0.3);
}

.yellowPaga .bg-6 {
	color: #4A4A4A;
	height: 520px;
}
.yellowPaga .bg-6 .bg-cont .solution-wrapper {
	margin-left: 190px;
}
.yellowPaga .bg-6 .bg-cont .solution-wrapper p a {
	text-decoration: underline;
	color: #3C386A;
}

.yellowPaga .bg-3 .bg-cont .solution-wrapper ul {
	padding-left: 1px;
}
.yellowPaga .bg-cont {
	margin-top: 50px;
}

.yellowPaga .bg-1 .bg-cont {
	margin-top: 80px;
}

.yellowPaga .bg-2 .bg-cont {
	margin-top: 105px;
}
.yellowPaga .bg-3 .bg-cont {
	margin-top: 100px;
}

.yellowPaga .bg-6 .bg-cont {
	margin-top: 60px;
}

.yellowPaga .bg-cont-header {
	font-weight: 700 !important;
	font-size: 17px;
}
.yellowPaga .bg-cont p {
	font-weight: 400;
}
.yellowPaga .bg-cont .solution-wrapper {
	margin-left: 210px;
}
.yellowPaga .bg-4 .bg-cont .solution-wrapper {
	margin-left: 255px;
}
.yellowPaga .bg-cont-between {
	margin-top: 60px;
}
.yellowPaga .bg-cont-between-img {
	margin-top: 105px;
}

.yellowPaga .current {
  margin-top: 135px
}

.yellowPaga .screen-img p {
	position: absolute;
	text-align: center;
	left: 0;
	right: 0;
	margin-top: 55px;
	font-weight: 600;
	font-size: 23px;
  color: #4A4A4A;
}

.yellowPaga .screen-img img {
	width: 100%;
}

.funMob {
	display: none;
}
.p1 {
  margin-bottom:0;
}
.p {
  margin-top: 5px;
}
 .li {
  margin-bottom: 28px !important;
}

.yellowPaga .bg-5 .solution-cont {
  display: flex;
  margin-top: 75px;
  width: 128%;
  justify-content: space-between;
}

.yellowPaga .bg-5 .solution-cont p {
    width: 525px;
    line-height: 35px;
    margin-top: -12px;
}

.yellowPaga .bg-5 .bg-cont .solution-wrapper {
  margin-left: 100px;
}

.yellowPaga .bg-5 .cont2 {
  margin-top: 120px;
}

.yellowPaga .bg-5 .cont2 ul {
  padding-left: 0;
}

.yellowPaga .bg-5 .cont2 .bolda{
  font-weight: 600;
}

.yellowPaga .bg-5 .cont2 ul li{
  margin-left: 26px;
  margin-bottom: 26px;
}

@media only screen and (max-width: 1280px) {
  .yellowPaga-details {
    height: 780px;
}
  .yellowPaga-details .details-intro h3 {
    line-height: 58px; 
    margin-top: 88px
  }
  .yellowPaga .bg-3 {
    height: 435px;
  }
  .yellowPaga .bg-4 {
    height: 1684px;
  }

  .yellowPaga-details .details-img {
    left: -18px;
  }
  .yellowPaga .bg-5 .solution-cont {
    width: 120%;
  }
}

@media only screen and (max-width: 1024px) { 
	.yellowPaga .bg-1,
	.yellowPaga .bg-2,
	.yellowPaga .bg-3,
	.yellowPaga .bg-4,
	.yellowPaga .bg-5,
	.yellowPaga .bg-6{
		height: auto;
	}

  .yellowPaga-details .details-intro h3 {
    width: 536px;
    margin-left: 18px;
  }

	.yellowPaga-details .details-img {
		top: -534px;
    left: 550px;
		z-index: -1;
	}
	.yellowPaga-details.row {
		flex-wrap: wrap;
	}
	.yellowPaga-details .details-img img {
		width: 430px;
		margin-top: -345px;
        margin-left: -46px;
	}
	.yellowPaga-details .details-img-overlay {
		height: 330px;
		width: 330px;
	}
	.yellowPaga .bg-cont div {
		margin-top: -60px;
	}
	.screen-img img {
		width: 800px;
	}

  .yellowPaga .bg-1 .bg-cont {
    margin-top: 70px;
    padding-bottom: 32px;
  }
  .yellowPaga .bg-2 .bg-cont,
  .yellowPaga .bg-3 .bg-cont,
  .yellowPaga .bg-4 .container,
  .yellowPaga .bg-6 .bg-cont {
    /* margin-top: 70px; */
    padding-bottom: 32px;
  }
  .yellowPaga .current {
    margin-top: 70px;
  }

  .yellowPaga .bg-5 .solution-cont p {
    width: 490px;
  }
  .yellowPaga .bg-5 .solution-cont {
    width: 105%;
}
}

@media only screen and (max-width: 912px) { 
  .yellowPaga .bg-cont {
    margin-top: 40px !important;
}
  .yellowPaga .bg-5 .solution-cont p {
    width: 325px;
  }

  .yellowPaga .screen-img p {
    font-size: 20px;
  }
  
}

@media only screen and (max-width: 768px) {
	.yellowPaga-details .details-img-overlay {
		height: 240px;
		width: 240px;
	}
	.yellowPaga-details .details-img img {
		width: 308px;
		margin-top: -254px;
		margin-left: -30px;
	}
	.yellowPaga-details .details-img {
		top: -494px;
		left: 440px;
		z-index: -1;
	}
	.yellowPaga-details .details-intro p {
		font-size: 13px;
	}
	.yellowPaga .bg-cont {
		margin-top: 45px;
	}
	.screen-img img {
		width: 600px;
	}
	.yellowPaga .screen-img p {
		font-size: 18px;
    margin-top: 27px;
	}
  .yellowPaga .bg-cont-between-img {
    margin-top: 66px;
}
.yellowPaga .bg-5 .solution-cont {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.yellowPaga .bg-5 .solution-cont p {
  width: auto;
}
.yellowPaga .bg-5 .solution-cont img {
  width: 300px;
  margin-top: 35px;
}
}

@media only screen and (max-width: 574px) {
  .yellowPaga-details .details-overlay {
    left: 1px;
  }
	.yellowPaga-details .details-intro h3 {
		font-size: 35px;
    width: auto;
    margin-top: 84px;
    margin-left: 6px;
	}
  .produ {
    width: auto;
}
	.yellowPaga-bg .row {
		flex-direction: column;
	}
	.yellowPaga-details.row {
    flex-wrap: nowrap;
}
.yellowPaga-details {
	height: auto;
}
.yellowPaga .bg-cont .solution-wrapper {
  margin-left: 0px;
  margin-top: 0px;
}
.yellowPaga .bg-5 .bg-cont .solution-wrapper {
  margin-left: 0px;
}
	.screen-img img {
		width: 417px;
	}
	.yellowPaga .screen-img p {
		margin-top: 20px;
		font-size: 16px;
	}

  .yellowPaga .bg-cont {
    margin-top: 31px !important;
}

  .yellowPaga .bg-6 .bg-cont .solution-wrapper {
    margin-left: 0;
	}
  .yellowPaga .bg-5 .cont1 {
    margin-top: 15px;
}
	.yellowPaga .bg-cont-between-img {
		margin-top: 55px;
	}
	.funDes {
		display: none;
	}

	.funMob {
		display: block;
	}

	.yellowPaga-details .details-img {
		top: 	18px;
    left: 0px;
    right: 0px;
		width: 68%;
		z-index: 1;
		margin: 0 auto !important;
	}
	
	.yellowPaga-details .details-img-overlay {
    height: 200px;
    width: 200px;
}
.yellowPaga-details .details-img img {
	width: 240px;
	margin-top: -236px;
	margin-left: -20px;
}
.yellowPaga .current {
  margin-top: 34px;
}
.yellowPaga .bg-5 .solution-cont img {
  width: 220px;
}
}

@media only screen and (max-width: 414px) {
	.yellowPaga {
    padding: 6px 0px 0px;
}
	.yellowPaga-details .details-overlay {	
			width: 60px;
			height: 60px;
			top: 34px;
			left: 3px;
	}
	.yellowPaga-details .details-intro h3 {
		font-size: 28px;
    line-height: 43px;
    margin: 12px 0 35px;
    margin-left: -6px;
    margin-top: 28px;
	}

	.yellowPaga-details .details-intro div p {
    margin: 1px 0;
}

	.yellowPaga-back img {
    width: 27px;
}

	.yellowPaga-back p {
    font-size: 16px;
}
	.yellowPaga .bg-cont-between {
    margin-top: 35px;
}
	.yellowPaga-bg .row {
		flex-direction: column;
	}
	.yellowPaga .bg-cont {
		margin-top: 11px;
	}
	.yellowPaga .bg-cont div {
		margin-left: -2px;
		margin-top: 0px;
	}
	.yellowPaga-details,
	.yellowPaga .bg-1,
	.yellowPaga .bg-2,
	.yellowPaga .bg-3,
	.yellowPaga .bg-4,
	.yellowPaga .bg-5,
	.yellowPaga .bg-6{
		height: auto;
	}
	.screen-img img {
		width: 304px;
		margin-left: 0px;
	}
	.yellowPaga .screen-img p {
		font-size: 10px;
		margin-top: 10px;
	}
	.yellowPaga .bg-6 {
		padding-bottom: 70px;
	}
	.yellowPaga .bg-6 .bg-cont div {
		margin-left: 0px;
	}
	.yellowPaga .bg-cont .solution-wrapper p {
		font-size: 15px;
	}
}

@media only screen and (max-width: 320px){
	.yellowPaga {
		padding: 1px 0px 0px;
	}
	.yellowPaga-details .details-intro h3 {
		font-size: 17px;
    line-height: 27px;
		margin: 10px 0;
    margin-top: 18px;
	}
	.yellowPaga-details .details-intro div {
		margin: 19px 0;
	}
	.yellowPaga-details .details-intro p {
		font-size: 11px;
	}
	.yellowPaga .bg-cont div p {
		font-size: 11px;
	}
	
	.screen-img img {
		width: 200px;
	}
	.yellowPaga-details .details-img-overlay {
		height: 178px;
		width: 178px;
	}
	
	.yellowPaga-details .details-img img {
		width: 200px;
		margin-top: -186px;
		margin-left: -20px;
	}
  .yellowPaga .bg-5 .solution-cont img {
    width: 195px;
}
	}