.morenaLeader {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  padding: 50px 0px 0px;
}

.container {
  max-width: 90%;
  margin: auto;
}

/*---------------------
  Morena Leader Screen Styles 
-----------------------*/
.morenaLeader-back {
  position: relative;
}

.morenaLeader-back img {
  width: 30px;
}

.morenaLeader-back p {
  color: rgba(117, 117, 117, 1);
  font-size: 19px;
  font-weight: 400;
  margin: 0;
  margin-top: 3.4px;
  margin-left: -5px;
}

.morenaLeader-details {
  height: 790px;
  padding: 20px;
  position: relative;
}

.morenaLeader-details.row {
  flex-wrap: nowrap;
}

.morenaLeader-details .details-overlay {
  position: absolute;
  width: 110px;
  height: 110px;
  background: #ffdada;
  border-radius: 50%;
  z-index: -1;
  top: 64px;
  left: 17px;
}

.morenaLeader-details .details-intro h3 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 46px;
  margin: 35px 0;
  margin-left: 30px;
  line-height: 68px;
  margin-top: 77px;
  color: #3C386A;
}

.morenaLeader-details .details-intro p {
  color: #4A4A4A;
  font-size: 15px;
  margin: 20px 0;
  font-weight: 400;
}

.morenaLeader-details .details-intro p a {
  text-decoration: underline;
  color: #4A4A4A;
}

.morenaLeader-details .details-intro p b {
  font-weight: 700;
}

.morenaLeader-details .details-intro div {
  margin: 45px 0;
}

.morenaLeader-details .details-intro div p {
  margin: 10px 0;
}

.morenaLeader-details .details-img {
  position: relative;
  top: 100px;
  left: -58px;
}

.morenaLeader-details .details-img-overlay {
  background: rgba(241, 243, 247, 1);
  position: relative;
  height: 430px;
  width: 430px;
  border-radius: 30px;
  transform: rotate(45deg);
}

.morenaLeader-details .details-img img {
  position: absolute;
  margin-top: -419px;
  margin-left: -14px;
}

.morenaLeader-bg {
  padding: 30px;
}

.morenaLeader .bg-1 {
  margin-top: 20px;
  background: #3C386A;
  color: #fff;
  height: 440px;
}

.morenaLeader .bg-2 {
  color: #4A4A4A;
  height: 550px;
}

.morenaLeader .bg-3 {
  color: #4A4A4A;
  height: 400px;
  background: rgba(255, 218, 218, 0.3);
}

.morenaLeader .bg-5 {
  height: 1190px;
  color: #4A4A4A;
  margin-top: 80px;
}

.morenaLeader .bg-5b {
  height: 1050px;
  color: #4A4A4A;
  background: rgba(255, 218, 218, 0.3);
}

.morenaLeader .bg-6 {
  color: #4A4A4A;
  height: 460px;
}

.morenaLeader .bg-6 .bg-cont div {
  margin-left: 190px;
}

.morenaLeader .bg-6 .bg-cont div p a {
  text-decoration: underline;
  color: #3C386A;
}

.morenaLeader .bg-cont {
  margin-top: 50px;
}

.morenaLeader .bg-cont-header {
  font-weight: 700 !important;
  font-size: 17px;
}

.morenaLeader .bg-cont p {
  font-weight: 400;
}

.morenaLeader .bg-cont div {
  margin-left: 210px;
}

.morenaLeader .bg-4 .bg-cont div {
  margin-left: 255px;
}

.morenaLeader .bg-cont-between {
  margin-top: 60px;
}

.morenaLeader .bg-cont-between-img {
  margin-top: 105px;
}

.morenaLeader .screen-img p {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin-top: 55px;
  font-weight: 600;
  font-size: 23px;
  color: #4A4A4A;
}

.morenaLeader .screen-img img {
  width: 100%;
}

.funMob {
  display: none;
}

.prod {
  width: 750px;
}

.morenaLeader .bg-2 .bg-cont {
  margin-top: 68px;
}

.morenaLeader .bg-2-ul {
  margin-left: 182px !important;
}

.morenaLeader .bg-2 ul {
  padding-left: 0px;
}

.morenaLeader .bg-2 ul li {
  margin-bottom: 40px;
}

.morenaLeader .bg-2 ul p {
  width: 655px;
}

.morenaLeader .bg-3 .bg-cont div {
  margin-left: 160px;
}

.morenaLeader .bg-3b {
  color: #4A4A4A;
  height: 580px;
  background: rgba(255, 218, 218, 0.3);
}

.morenaLeader .bg-3b .bg-cont div {
  margin-left: 165px;
}

.morenaLeader .bg-5 .bg-cont div {
  margin-left: 195px;
}

.morenaLeader .bg-5b .bg-cont div {
  margin-left: 155px;
}

.morenaLeader .bg-5b .bg-cont {
  margin-top: 110px;
}

.morenaLeader .bg-6 ul li {
  margin-bottom: 40px;
}

.ging {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 18px;
  width: 73%;
}

.ging1 {
  margin-top: 20px;
}

.ging2 {
  display: flex;
  flex-direction: column;
  width: 68%;
  margin-top: 25px;
}

.ging3 {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 25px;
}

.mia {
  width: fit-content;
  object-fit: contain;
}

.ging2 .bottom,
.ging3 .bottom {
  margin-top: 65px;
}

@media only screen and (max-width: 1280px) {
  .ging {
    width: 78%;
  }

  .morenaLeader .bg-3 {
    height: 360px;
  }

  .morenaLeader .bg-3 .bg-cont div {
    margin-left: 78px;
  }

  .morenaLeader .bg-3b .bg-cont div {
    margin-left: 64px;
  }
}

@media only screen and (max-width: 1024px) {

  .morenaLeader .bg-1,
  .morenaLeader .bg-2,
  .morenaLeader .bg-3,
  .morenaLeader .bg-3b,
  .morenaLeader .bg-5,
  .morenaLeader .bg-5b,
  .morenaLeader .bg-6 {
    height: auto;
    padding-bottom: 60px;
  }

  .prod {
    width: 565px;
  }

  .morenaLeader-details .details-img {
    top: -508px;
    left: 554px;
    z-index: -1;
  }

  .morenaLeader-details.row {
    flex-wrap: wrap;
  }

  .morenaLeader-details .details-img img {
    width: 390px;
    margin-top: -323px;
    margin-left: -20px;
  }

  .morenaLeader-details .details-img-overlay {
    height: 330px;
    width: 330px;
  }

  .morenaLeader .bg-cont div {
    margin-top: -36px;
  }

  .morenaLeader .bg-2 .bg-cont div {
    margin-top: 0px;
  }

  .morenaLeader .bg-2 ul p {
    width: 542px;
  }

  .morenaLeader .bg-2-ul {
    margin-left: 110px !important;
  }

  .ging {
    width: 72%;
  }

  .ging .mi2 {
    width: 400px;
  }

  .ging .mi1,
  .ging .mi3 {
    width: 100px;
  }

  .morenaLeader .bg-3 .bg-cont div {
    margin-left: 229px;
  }

  .ging1 .mi4 {
    width: 620px;
  }

  .morenaLeader .bg-3b .bg-cont div {
    margin-left: 225px;
  }

  .ging2 .mi5 {
    width: 500px;
  }

  .ging2 .bottom {
    width: 350px;
  }

  .morenaLeader .bg-5 .bg-cont div {
    margin-left: 225px;
  }

  .ging3 .mi6 {
    width: 350px;
  }

  .ging3 .bottom {
    width: 350px;
  }

  .morenaLeader .bg-5b .bg-cont div {
    margin-left: 120px;
    margin-top: 22px;
  }

  .morenaLeader .bg-6 .bg-cont div {
    margin-left: 155px;
    margin-top: 12px;
  }
}

@media only screen and (max-width: 912px) {
  .morenaLeader .bg-2-ul {
    margin-left: 215px !important;
  }

  .morenaLeader .bg-2 .bg-cont div {
    margin-top: -53px;
  }

  .morenaLeader .bg-2 ul p {
    width: auto;
  }

  .ging .mi2 {
    width: 340px;
  }

  .morenaLeader .bg-3 .bg-cont div {
    margin-left: 220px;
  }

  .ging1 .mi4 {
    width: 500px;
  }
}

@media only screen and (max-width: 820px) {
  .ging .mi2 {
    width: 300px;
  }

  .ging .mi1,
  .ging .mi3 {
    width: 85px;
  }

  .ging1 .mi4 {
    width: 460px;
  }

  .morenaLeader .bg-1 .bg-cont div {
    margin-top: -52px;
  }

  .morenaLeader .bg-5b .bg-cont div {
    margin-left: 300px;
    margin-top: -33px;
  }

  .morenaLeader .bg-6 .bg-cont div {
    margin-top: -49px;
  }

  .morenaLeader .bg-5 .bg-cont div {
    margin-left: 200px;
    margin-top: -30px;
  }
}

@media (max-width: 768px) {
  .morenaLeader-details .details-img-overlay {
    height: 240px;
    width: 240px;
  }

  .morenaLeader-details .details-img img {
    width: 308px;
    margin-top: -254px;
    margin-left: -30px;
  }

  .morenaLeader-details .details-img {
    top: -494px;
    left: 440px;
    z-index: -1;
  }

  .morenaLeader-details .details-intro p {
    font-size: 13px;
  }

  .morenaLeader .bg-cont {
    margin-top: 45px;
  }

  .morenaLeader .bg-cont div {
    margin-left: 190px;
  }

  .morenaLeader .bg-3 .bg-cont div {
    margin-left: 198px;
  }

  .morenaLeader .bg-3b .bg-cont div {
    margin-left: 205px;
  }

  .ging2 .mi5 {
    width: 458px;
  }
}

@media only screen and (max-width: 574px) {
  .morenaLeader-details .details-intro h3 {
    font-size: 35px;
    line-height: 54px;
    margin-top: 93px;
  }

  .prod {
    width: auto;
  }

  .morenaLeader-bg .row {
    flex-direction: column;
  }

  .morenaLeader-details.row {
    flex-wrap: nowrap;
  }

  .morenaLeader-details {
    height: auto;
  }

  .morenaLeader .bg-cont div {
    margin-left: 0px;
    margin-top: 0px;
  }

  .morenaLeader .bg-1 .bg-cont div {
    margin-top: 0px;
  }

  .morenaLeader .bg-2-ul {
    margin-left: 0 !important;
  }

  .morenaLeader .bg-2 .bg-cont div {
    margin-top: 0px;
  }

  .morenaLeader .bg-3b .bg-cont div {
    margin-left: 0px;
  }

  .morenaLeader .bg-3 .bg-cont div {
    margin-left: 0px;
  }

  .morenaLeader .bg-6 .bg-cont div {
    margin-left: 0;
  }

  .morenaLeader .bg-cont-between-img {
    margin-top: 55px;
  }

  .morenaLeader .bg-3 {
    padding-bottom: 20px;
  }

  .morenaLeader .bg-5 {
    margin-top: 35px;
  }

  .morenaLeader .bg-5 .bg-cont div {
    margin-left: 0;
    margin-top: 16px;
  }

  .morenaLeader .bg-5b .bg-cont div {
    margin-left: 0;
    margin-top: 13px;
  }

  .morenaLeader .bg-6 .bg-cont div {
    margin-top: 0;
  }

  .ging,
  .ging1,
  .ging2,
  .ging3 {
    width: 100%;
    align-items: center;
  }

  .ging .mi1,
  .ging .mi3 {
    width: 80px;
  }

  .ging .mi2 {
    width: 270px;
  }

  .ging1 .mi4 {
    width: 430px;
  }

  .ging2 .mi5 {
    width: 430px;
  }

  .ging2 .bottom {
    align-self: flex-start;
  }

  .funDes {
    display: none;
  }

  .funMob {
    display: block;
  }

  .morenaLeader-details .details-img {
    top: 18px;
    left: 0px;
    right: 0px;
    width: 68%;
    z-index: 1;
    margin: 0 auto !important;
  }

  .morenaLeader-details .details-img-overlay {
    height: 200px;
    width: 200px;
  }

  .morenaLeader-details .details-img img {
    width: 240px;
    margin-top: -236px;
    margin-left: -20px;
  }
}

@media only screen and (max-width: 414px) {
  .morenaLeader {
    padding: 6px 0px 0px;
  }

  .morenaLeader-details .details-overlay {
    width: 60px;
    height: 60px;
    top: 34px;
    left: 3px;
  }

  .morenaLeader-details .details-intro h3 {
    font-size: 28px;
    line-height: 42px;
    margin: 12px 0 35px;
    margin-left: -6px;
    margin-top: 26px;
  }

  .morenaLeader-details .details-intro div p {
    margin: 1px 0;
  }

  .morenaLeader-back img {
    width: 27px;
  }

  .morenaLeader-back p {
    font-size: 16px;
  }

  .morenaLeader .bg-cont-between {
    margin-top: 35px;
  }

  .morenaLeader-bg .row {
    flex-direction: column;
  }

  .morenaLeader .bg-cont {
    margin-top: 11px;
  }

  .morenaLeader .bg-cont div {
    margin-left: -2px;
    margin-top: 0px;
  }

  .morenaLeader-details,
  .morenaLeader .bg-1,
  .morenaLeader .bg-2,
  .morenaLeader .bg-3,
  .morenaLeader .bg-4,
  .morenaLeader .bg-5,
  .morenaLeader .bg-6 {
    height: auto;
  }

  .ging .mi1,
  .ging .mi3 {
    width: 60px;
  }

  .ging .mi2 {
    width: 200px;
  }

  .ging1 .mi4 {
    width: 320px;
  }

  .ging2 .mi5 {
    width: 342px;
  }

  .ging2 .bottom {
    width: 270px;
    margin-top: 50px;
  }

  .ging3 .mi6 {
    width: 318px;
  }

  .ging3 .bottom {
    width: 318px;
    margin-top: 50px;
  }

  .morenaLeader .bg-6 {
    padding-bottom: 70px;
  }

  .morenaLeader .bg-6 .bg-cont div {
    margin-left: 0px;
  }

  .morenaLeader .bg-cont div p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .ging .mi2 {
    width: 170px;
  }

  .ging1 .mi4 {
    width: 285px;
  }

  .ging2 .mi5 {
    width: 295px;
  }

  .ging2 .bottom {
    width: 240px;
  }

  .ging3 .mi6 {
    width: 275px;
  }

  .ging3 .bottom {
    width: 275px;
  }

  .morenaLeader .bg-5b .bg-cont {
    margin-top: 60px;
  }

}

@media only screen and (max-width: 320px) {
  .morenaLeader {
    padding: 1px 0px 0px;
  }

  .morenaLeader-details .details-intro h3 {
    font-size: 17px;
    margin: 10px 0;
    margin-top: 19px;
    line-height: 26px;
  }

  .morenaLeader-details .details-intro div {
    margin: 19px 0;
  }

  .morenaLeader-details .details-intro p {
    font-size: 11px;
  }

  .morenaLeader .bg-cont div p {
    font-size: 11px;
  }

  .ging .mi1,
  .ging .mi3 {
    width: 38px;
  }

  .ging .mi2 {
    width: 140px;
  }

  .ging1 .mi4 {
    width: 208px;
  }

  .ging2 .mi5 {
    width: 223px;
  }

  .ging2 .bottom {
    width: 183px;
  }

  .ging3 .mi6 {
    width: 220px;
  }

  .ging3 .bottom {
    width: 220px;
  }

  .morenaLeader-details .details-img-overlay {
    height: 178px;
    width: 178px;
  }

  .morenaLeader-details .details-img img {
    width: 200px;
    margin-top: -186px;
    margin-left: -20px;
  }
}