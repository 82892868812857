.home {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	flex-wrap: wrap;
	padding-bottom: 7rem;
}

.home_overlay {
	position: absolute;
	width: 91%;
	height: 760px;
	top: -118px;
	right: 0;
	background-color: rgba(255, 218, 218, 0.2);
}

/*-------------------
  Home Intro Styles 
--------------------*/
.home_intro {
	height: 500px;
	width: 85%;
	overflow-x: hidden;
	position: relative;
	display: block;
	margin: auto;
	margin-top: 65px;
}

.home_intro h1 {
	font-family: "Playfair Display", serif;
	font-weight: 700;
	font-size: 115px;
	margin: 35px 0;
	line-height: 140px;
	color: #282547;
}
.home_intro p {
	color: #282547;
	font-weight: 400;
	font-size: 18px;
}

.home_intro-desc {
	width: 920px;
	line-height: 45px;
}

.home_intro-details {
	background: #FFECEC;
	width: 972px;
	padding: 10px 0px;
	display: flex;
}
.home_intro-details p {
	margin: 0;
	margin-left: 35px;
}
.home_intro-details a {
	text-decoration: underline;
	color: inherit;
}
.home_intro-details img {
	margin-left: 10px;
}

/*-------------------
  Home caseStudies Styles 
--------------------*/
.home_caseStudies {
	display: flex;
	padding: 78px 0 170px;
	width: 100%;
	position: relative;
	top: 58px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.homeHeader {
  font-family: "Playfair Display", serif;
  color: #282547;
	font-weight: 900;
	font-size: 48px;
	line-height: 64px;
	z-index: 1;
}

.homeHeader::before {
	content: "";
	height: 16px;
	width: 295px;
	background-color: #D8D7E1;
	position: absolute;
	z-index: -1;
	margin-top: 31px;
	margin-left: 36px;
}

.case-wrapper {
	background: #282547;
	margin: 0 auto;
	width: 82%;
	height: 355px;
	justify-content: space-between;
	align-items: center;
	padding: 0 28px;
}

.swiper-slide, .wiper-slide-active {
	display: flex;
	justify-content: space-between;
}

.case-details {
    margin-left: 90px;
    height: 257px;
    margin-top: 78px;
}

.case-details h3 {
		font-weight: 700;
		font-size: 48px;
		line-height: 69px;
		letter-spacing: 0.005em;
		font-family: "Playfair Display", serif;
    color: #FADADB;
		margin-bottom: 5px;
		margin-top: 10px;
}

.case-details > p {
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 0.001em;
	font-family: 'Montserrat', sans-serif;
	color: #FADADB;
	margin-top: -8px;
}

.case-details-btns {
	align-items: center;
	margin-top: 15px;
}

.case-details-btns a,
.case-details-btns p {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	font-family: 'Montserrat', sans-serif;
}

.case-details-btns a{
	color: #141323;
	background-color: #FEF8F8;
	padding: 7px 15px;
	margin-right: 20px;
	border-radius: 5px;
	cursor: pointer;
}

.case-details-btns span {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.case-details-btns span p {
	color: #FFFFFF;
}

.case-details-btns span button {
	border-radius: 50px;
	border: none;
	outline: none;
	width: 30px;
	height: 30px;
	background: transparent;
	margin-left: 2px;
	cursor: pointer;
}

.case-details-btns span button img {
	object-fit: cover;
	vertical-align: middle;
	width: 100%;
}

.case-img {
	width: 480px;
	margin-top: 61px;
	margin-right: 100px ;
}

.case-img img {
	width: 480px;
}



/*-------------------
  Home liveProjects Styles 
--------------------*/
.home_liveProjects {
	display: flex;
	padding: 65px 90px 100px;
	width: 100%;
	position: relative;
	top: 58px;
	justify-content: center;
	flex-direction: column;
	background: #FEF8F8;
}

.home_liveProjects .homeHeader {
	text-align: start;
	margin-top: 10px;
}

.home_liveProjects .homeHeader::before {
	width: 424px;
}

.live-detailsCont {
	gap: 2rem;
	align-items: center;

}

.live-details {
	width: 530px;
  background: #FFFFFF;
  border-radius: 16px;
	padding: 1rem 2rem 1.8rem;
}

.live-details h4 {
	font-family: "Playfair Display", serif;
	font-weight: 900;
	font-size: 32px;
	line-height: 43px;
	color: #282547;
	margin-top: 10px;
	margin-bottom: 5px;
}

.live-details span {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.live-details span p {
	font-weight: 400;
	font-size: 18px;
	line-height: 32px;
	color: #282547;
	width: 348px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.live-details span a {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #141323;
	font-family: 'Montserrat', sans-serif;
	background: #FBE0E1;
  border-radius: 5px;
	padding: 8px 14px;
	cursor: pointer;
}



/*-------------------
  Home Media Query
--------------------*/
@media only screen and (max-width: 1280px) {
 .case-details {
	height: auto;
}
.case-details h3 {
	font-size: 38px;
	line-height: 58px;
}
	.live-details {
		width: 485px;
	}

	.live-details span p  {
		width: 336px;
	}
}


@media only screen and (max-width: 1024px) {
	.home_intro h1 {
    font-size: 95px;
}
	.home_intro-details {
    width: 730px;
}
.home_intro-desc {
	width: 800px;
	line-height: 34px;
}
	.home_intro p {
    font-size: 14px;
		margin-left: 7px;
}

.case-wrapper {
	flex-wrap: nowrap;
	width: 83%;
	padding: 0 52px;
}


.case-details h3 {
	font-size: 30px;
	line-height: 46px;
}

.case-img {
	width: 390px;
	margin-top: 121px;
}
.case-img img {
	width: 390px;
}

.live-details {
	width: 780px;
}

.live-details span p {
	width: 550px;
}
}

@media only screen and (max-width: 912px) {
	.home_intro h1 {
    font-size: 70px;
  }

	.home_intro-desc {
    width: 600px;
}

.home_intro-details {
	width: 598px;
}

.home_intro-details img {
	margin-left: 5px;
	margin-right: 10px;
	margin-top: -18px;
}

.case-img {
	margin-top: 124px;
	margin-right: 35px;
}

.case-details {
	margin-left: 42px;
	margin-top: 78px;
}

	.live-details {
    width: 640px;
}

	.live-details span p {
			width: 478px;
	}
}


@media only screen and (max-width: 820px) {

	.home_intro {
    width: 100%;
		margin-left: 42px;
}

.live-details {
	width: 585px;
}

.live-details span p {
	width: 435px;
}

.case-img  {
	width: 352px;
	margin-top: 125px;
}

.case-img img {
	margin-top: 76px;
	width: 259px;
}
}

@media (max-width: 768px) {
	.home_intro h1 {
    font-size: 83px;
}
  .home_intro-details {
    width: 672px;
}
.home_intro p {
	font-size: 13px;
}

.case-details h3 {
	font-size: 30px;
}

.live-details {
	width: 528px;
}

.live-details span p {
	width: 390px;
}
.swiper-wrapper .swiper-slide {
  width: 100% !important;
	scroll-snap-align:  none !important;
}

.case-details {
	margin-left: 12px;
}

}

@media only screen and (max-width: 574px) {
	.home_overlay {
		width: 90%;
		height: 600px;
		/* height: 630px; */
	}

	.home {
		padding-bottom: 0;
	}

	.home_intro {
		height: 464px;
    margin-left: 28px;
		margin-top: 34px;
}
	
 .home_intro-desc {
	width: 462px;
	/* width: 340px; */
	/* margin: 0 auto; */
	line-height: 34px;
	/* margin-left: 15px; */
	margin-left: 0px !important;
}
.home_intro-details {
	width: 84%;
	/* width: 92%; */
	/* margin: 0 auto; */
}
.home_intro p {
	font-size: 15px;
}

.home_intro-details p {
	font-size: 12px;
	margin-left: 11px;
}
.home_intro-details img {
	width: 15px;
	/* margin-left: 139px;
  margin-top: 18px; */
	margin-left: 203px;
	margin-top: 16px;
  position: absolute;
}
.home_intro h1 {
	font-size: 35px;
	line-height: 28px;
	margin-left: 0px;
	/* margin-left: 14px; */
	width: 80%;
	/* margin: 0 auto; */
}

.home_caseStudies {
	top: 0;
	padding: 10px 0 0px;
}

.homeHeader {
	font-size: 29px;
  line-height: 60px;
	padding-bottom: 10px;
	margin-left: -15px;
}

.homeHeader::before {
	content: "";
	height: 10px;
	width: 178px;
	background-color: #D8D7E1;
	position: absolute;
	z-index: -1;
	margin-top: 29px;
	margin-left: 22px;
}

.swiper-slide, .wiper-slide-active {
	justify-content: center;
	flex-direction: column;
}

.case-wrapper {

	width: 100%;
	padding: 0 0px;
	padding-top: 5px;
	justify-content: center;
	flex-wrap: nowrap;
	height: auto;
}

.case-details {
	margin-left: 0px;
	height: auto;
	margin-top: 0px;
}

.case-details h3 {
  font-size: 24px;
line-height: 64px;
text-align: center;
}

.last-2 h3{
  margin: 0 auto;
  width: 250px;
	line-height: 45px;
	margin-top: 30px;
}

.case-details > p {
text-align: center;
width: 279px;
margin: 0 auto;
line-height: 24px;
margin-top: -7px;
}

.case-details-btns {
	flex-direction: column;
}

.case-img {
	margin: 0 auto;
	width: 300px;
}

.case-img img {
	width: 300px;
	margin: 0 auto;
	margin-top: 22px;
	margin-bottom: -3.5px;
}

.case-details-btns a {
	margin-right: 0;
}
.case-details-btns span {
	margin-top: 15px;
}

.home_liveProjects {
	padding: 65px 0px 100px;
	top: -2px;
}

.live-details {
	width: 78%;
	margin: 0 auto;
	padding: 1rem 1.3rem 1.8rem;
}

.live-details h4 {
	font-size: 24px;
line-height: 32px;
}

.live-details span {
	flex-direction: column;
	align-items: flex-start;
}

.live-details span p {
	font-size: 16px;
line-height: 24px;
margin-top: 8px;
    margin-bottom: 12px;
}

.home_liveProjects .homeHeader {
	text-align: center;
	font-size: 27px;
}


.home_liveProjects .homeHeader::before {
	width: 230px;
}

}

@media only screen and (max-width: 414px) {
.home_overlay {
	height: 530px;
	top: -150px;
}

 .home_intro-desc {
	 width: 340px;
	 margin-left: 15px;
	 line-height: 26px;
	}

 .home_intro-details {
	/* width: 90%; */
	margin-top: 20px;
}

.home_intro {
	height: 390px;
	overflow-x: visible;
	margin-top: 0px;
	width: 100%;
	margin: 0;
	margin-left: 18px;
 }
 .home_intro-details img {
	margin-left: 320px;
	margin-top: 22px;
}
.home_intro-details p {
	line-height: 20px;
}

.live-details span p {
	width: auto;
}

}

@media only screen and (max-width: 390px) {
	.home_intro-details {
		width: 90%;
	}

	.case-img img {
    margin-bottom: -3.5px;
}
}

@media only screen and (max-width: 375px) {
	.home_intro-details img {
    margin-left: 312px;
}
}

@media only screen and (max-width: 360px) {
	.home_intro-desc {
    width: 333px;
}

	.home_intro-details {
		width: 85%;
	}

	.home_intro-details img {
    margin-left: 90px;
		margin-top: 43px;
}
}