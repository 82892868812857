.tripzScreen {
	position: relative;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	padding: 50px 0px 0px;
}

.container {
	max-width: 90%;
	margin: auto;
}

/*---------------------
  Tripz Screen Styles 
-----------------------*/
.tripzScreen-back {
	position: relative;
}

.tripzScreen-back img {
	width: 30px;
}

.tripzScreen-back p {
	color: rgba(117, 117, 117, 1);
	font-size: 19px;
	font-weight: 400;
	margin: 0;
	margin-top: 3.4px;
	margin-left: -5px;
}

.tripzScreen-details {
	height: 650px;
	padding: 20px;
	position: relative;
}

.tripzScreen-details.row {
	flex-wrap: nowrap;
}

.tripzScreen-details .details-overlay {
	position: absolute;
	width: 110px;
	height: 110px;
	background: #ffdada;
	border-radius: 50%;
	z-index: -1;
	top: 64px;
	left: 17px;
}

.tripzScreen-details .details-intro h3 {
	font-family: "Playfair Display", serif;
	font-weight: 700;
	font-size: 46px;
	margin: 35px 0;
	margin-left: 32px;
	line-height: 140px;
	color: #3C386A;
}

.tripzScreen-details .details-intro p {
	color: #4A4A4A;
	font-size: 15px;
	margin: 20px 0;
	font-weight: 400;
}

.tripzScreen-details .details-intro p b {
	font-weight: 700;
}

.tripzScreen-details .details-intro div {
	margin: 45px 0;
}

.tripzScreen-details .details-intro div p {
	margin: 10px 0;
}

.tripzScreen-details .details-img {
	position: relative;
	top: 62px;
	left: 17px;
}

.tripzScreen-details .details-img-overlay {
	background: rgba(241, 243, 247, 1);
	position: relative;
	height: 430px;
	width: 430px;
	border-radius: 30px;
	transform: rotate(30deg);
}

.tripzScreen-details .details-img img {
	position: absolute;
	margin-top: -410px;
	margin-left: -39px;
}

.tripzScreen-bg {
	padding: 30px;
}

.tripzScreen .bg-1 {
	margin-top: 20px;
	background: #3C386A;
	color: #fff;
	height: 440px;
}

.tripzScreen .bg-2 {
	color: #4A4A4A;
	height: 450px;
}

.tripzScreen .bg-3 {
	color: #4A4A4A;
	height: 1350px;
	background: rgba(255, 218, 218, 0.3);
}

.tripzScreen .bg-6 {
	color: #4A4A4A;
	height: 400px;
}

.tripzScreen .bg-6 .bg-cont div {
	margin-left: 190px;
}

.tripzScreen .bg-6 .bg-cont div p a {
	text-decoration: underline;
	color: #3C386A;
}

.tripzScreen .bg-cont {
	margin-top: 50px;
}

.tripzScreen .bg-cont-header {
	font-weight: 700 !important;
	font-size: 17px;
}

.tripzScreen .bg-cont p {
	font-weight: 400;
}

.tripzScreen .bg-cont div {
	margin-left: 210px;
}

.tripzScreen .bg-cont-between {
	margin-top: 60px;
}

.tripzScreen .bg-cont-between-img {
	margin-top: 105px;
}

.tripzz {
	margin-left: -98px;
}

.funMob {
	display: none;
}

@media only screen and (max-width: 1024px) {

	.tripzScreen .bg-1,
	.tripzScreen .bg-2,
	.tripzScreen .bg-3,
	.tripzScreen .bg-6 {
		height: auto;
		padding-bottom: 60px;
	}

	.tripzScreen-details .details-img {
		top: -371px;
		left: 573px;
		z-index: -1;
	}

	.tripzScreen-details.row {
		flex-wrap: wrap;
	}

	.tripzScreen-details .details-img img {
		width: 430px;
		margin-top: -345px;
		margin-left: -46px;
	}

	.tripzScreen-details .details-img-overlay {
		height: 330px;
		width: 330px;
	}

	.tripzScreen .bg-cont div {
		margin-top: -60px;
	}

	.tripzz {
		width: 670px;
		margin-left: 0;
	}
}

@media only screen and (max-width: 912px) {
	.tripzz {
		width: auto;
	}
}

@media (max-width: 768px) {
	.tripzScreen-details {
		height: 570px;
	}

	.tripzScreen-details .details-img-overlay {
		height: 240px;
		width: 240px;
	}

	.tripzScreen-details .details-img img {
		width: 308px;
		margin-top: -254px;
		margin-left: -30px;
	}

	.tripzScreen-details .details-img {
		top: -318px;
		left: 497px;
		z-index: -1;
	}

	.tripzScreen-details .details-intro h3 {
		font-size: 33px;
	}

	.tripzScreen-details .details-intro p {
		font-size: 13px;
	}

	.tripzScreen .bg-cont {
		margin-top: 45px;
	}

	.tripzScreen .bg-cont div {
		margin-left: 190px;
	}

	.tripzScreen .bg-1 {
		height: 500px;
	}

	.tripzScreen .bg-2 {
		height: 555px;
	}

	.tripzScreen .bg-3 {
		height: 1265px;
	}

	.tripzScreen .bg-6 {
		height: 450px;
	}

	.tripzz {
		width: 430px;
	}
}

@media only screen and (max-width: 574px) {
	.tripzScreen-bg .row {
		flex-direction: column;
	}

	.tripzScreen-details.row {
		flex-wrap: nowrap;
	}

	.tripzScreen-details {
		height: auto;
	}

	.tripzScreen .bg-cont div {
		margin-left: 0px;
		margin-top: 0px;
	}

	.tripzScreen .bg-cont div,
	.tripzScreen .bg-6 .bg-cont div {
		margin-left: 0px;
	}

	.tripzScreen .bg-1,
	.tripzScreen .bg-2,
	.tripzScreen .bg-3,
	.tripzScreen .bg-6 {
		padding-bottom: 105px;
	}

	.funDes {
		display: none;
	}

	.funMob {
		display: block;
	}

	.tripzScreen-details .details-img {
		top: 2px;
		left: 0px;
		right: 0px;
		width: 68%;
		z-index: 1;
		margin: 0 auto !important;
	}

	.tripzScreen-details .details-img-overlay {
		height: 200px;
		width: 200px;
	}

	.tripzScreen-details .details-img img {
		width: 240px;
		margin-top: -236px;
		margin-left: -20px;
	}
}

@media only screen and (max-width: 414px) {
	.tripzScreen {
		padding: 6px 0px 0px;
	}

	.tripzScreen-details .details-overlay {
		width: 60px;
		height: 60px;
		top: 34px;
		left: 3px;
	}

	.tripzScreen-details .details-intro h3 {
		font-size: 28px;
		line-height: 38px;
		margin: 30px 0 35px;
		margin-left: -6px;
	}

	.tripzScreen-details .details-intro div p {
		margin: 1px 0;
	}

	.tripzScreen-back img {
		width: 27px;
	}

	.tripzScreen .bg-cont div p {
		font-size: 15px;
	}

	.tripzScreen-back p {
		font-size: 16px;
	}

	.tripzScreen .bg-cont-between {
		margin-top: 35px;
	}

	.tripzScreen-bg .row {
		flex-direction: column;
	}

	.tripzScreen .bg-cont {
		margin-top: 11px;
	}

	.tripzScreen .bg-cont div {
		margin-left: -2px;
		margin-top: 0px;
	}

	.tripzScreen-details {
		height: auto;
	}

	.tripzScreen .bg-1,
	.tripzScreen .bg-2,
	.tripzScreen .bg-3,
	.tripzScreen .bg-6 {
		height: auto;
		padding-bottom: 78px;
	}

	.tripzz {
		width: 304px;
		margin-top: 55px !important;
	}
}

@media only screen and (max-width: 375px) {
	.tripzz {
		width: 288px;
	}
}

@media only screen and (max-width: 360px) {
	.tripzz {
		width: 272px;
	}
}

@media only screen and (max-width: 320px) {
	.tripzScreen {
		padding: 1px 0px 0px;
	}

	.tripzScreen-details .details-img {
		top: -6px;
	}

	.tripzScreen-details .details-intro h3 {
		font-size: 17px;
		margin: 22px 0;
		line-height: 48px;
	}

	.tripzScreen-details .details-intro div {
		margin: 19px 0;
	}

	.tripzScreen-details .details-intro p {
		font-size: 11px;
	}

	.tripzScreen .bg-cont div p {
		font-size: 11px;
	}

	.tripzz {
		width: 200px;
	}

	.tripzScreen-details .details-img-overlay {
		height: 178px;
		width: 178px;
	}

	.tripzScreen-details .details-img img {
		width: 200px;
		margin-top: -186px;
		margin-left: -20px;
	}
}